* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Tahoma, Geneva, Verdana, sans-serif;
}
body {
  background: url("../public/images/wallpaper.jpg") no-repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
